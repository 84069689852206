//import React, {useContext, useEffect, useRef} from "react";
import React, {useContext, useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {notification} from "antd";
import {Block, Col, Row, Typography, Avatar, Image, Icon, Button} from "@bezlimit/bezlimit-ui";
//component
import avatarImg from "../../../../images/avatar.svg";
import EditLoginModal from "../../../modal/editLogin";
import EditPhoneModal from "../../../modal/editPhone";
import EditEmailModal from "../../../modal/editEmail";
import EditPasswordModal from "../../../modal/editPassword";
import Error from "../../../modal/error";
//store
import Store from "../../../../stores";
//core
import {LayoutContext, ModalContext} from "../../../../core/Contexts";
//service
import UserService from "../../../../services/UserService";

import "./style.scss";
import _ from "lodash";
import ImportantModal from "../../../modal/importantModal";
import {createSearchParams, useNavigate} from "react-router-dom";
import closeNPS from "../../../../images/delete_nps.svg";
import vectorNPS from "../../../../images/vector_nps.svg";
import NPS from "../../../../images/nps3.svg";

export const ProfileInfo: React.FC = observer(() => {
    const {t} = useTranslation();
    const contextModal = useContext(ModalContext);
    const uploadRef = useRef<any>(null);
    const navigate = useNavigate();
    const el: any = document.body.querySelector(".ant-notification-notice-close");
    const contextLayout = useContext(LayoutContext);

    const onCloseNotification = () => {
        if (_.isEqual(location.search, "") && location.href.includes("?")) {
            history.pushState({}, "", location.href.split("?")[0]);
            Store.nps.phone = null;
        }
    };

    useEffect(() => {
        onCloseNotification();
    }, [el]);

    const notif = () => {
        Store.nps.phone = Store.user.personal_data?.phone.toString();
        navigate({
            search: createSearchParams({
                nps: Store.user.personal_data?.phone.toString()
            }).toString(),
            pathname: "/numbers"
        });
        openNotification();
    };

    const openNotification = () => {
        notification.open({
            message: (
                <Typography as="div" className="massege">
                    <Typography as="div">
                        {"Заверши бронирование по акции «Не плати за связь»"}
                    </Typography>
                </Typography>
            ),
            description: (
                <Typography as="div" className="description">
                    <img src={NPS} width={38} />
                    {Store.user.personal_data?.phone}
                </Typography>
            ),
            className: "notification-nps",
            style: {
                width: 600
            },
            duration: 0,
            placement: "top",
            top: _.gte(contextLayout?.width, 431) ? 20 : 0,
            onClick: () => {
                navigate({
                    search: createSearchParams({
                        nps: Store.user.personal_data?.phone.toString()
                    }).toString(),
                    pathname: "/numbers"
                });
            },
            onClose: onCloseNotification,
            closeIcon: <img src={closeNPS} width={24} />,
            icon: <img src={vectorNPS} width={_.gte(contextLayout?.width, 431) ? 42 : 29} />
        });
    };

    const onLoginEdit = () => {
        contextModal?.setVisible(true);
        contextModal?.setChildren(<EditLoginModal login={Store.user.login} />);
    };

    const onPasswordEdit = () => {
        contextModal?.setVisible(true);
        contextModal?.setChildren(<EditPasswordModal email={Store.user.profile?.email} />);
    };

    const onPhoneEdit = () => {
        contextModal?.setVisible(true);
        contextModal?.setChildren(<EditPhoneModal phone={Store.user.personal_data?.phone} />);
    };
    const onEmailEdit = () => {
        contextModal?.setVisible(true);
        contextModal?.setChildren(<EditEmailModal email={Store.user.profile?.email} />);
    };
    const onClick = () => {
        contextModal?.setMaskStyle({
            background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(0deg, rgba(100, 40, 120, 0.65) 0%, rgba(100, 40, 120, 0.65) 100%), rgba(255, 255, 255, 0.05)",
            // @ts-ignore
            "-webkit-backdrop-filter": " blur(20px)",
            "backdrop-filter": " blur(20px)"
        });
        contextModal?.setCssClass(" bl-modal-important");
        contextModal?.setChildren(<ImportantModal onPhoneEdit={onPhoneEdit} />);
        contextModal?.setVisible(true);
    };

    const onUpload = async (e: any) => {
        const file = e.target.files[0];
        const fileSize = file?.size;
        if (fileSize && fileSize > 10485760) {
            const message = "Размер файла должен быть менее 10Мб";
            contextModal?.setVisible(true);
            contextModal?.setChildren(<Error customError={message} />);
            return;
        }

        if (
            file &&
            (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg")
        ) {
            try {
                await UserService.postAvatar(file);
                await UserService.getUsers();
            } catch (e: any) {
                contextModal?.setVisible(true);
                contextModal?.setChildren(<Error />);
            }
        }
    };

    const onChooseClick = () => {
        const callback = () => {
            uploadRef?.current?.click?.();
        };
        return callback();
    };

    return (
        <Block className="bl-profile-info">
            <Row gutter={[16, 8]}>
                <Col xs={24} md={8} className="profile-avatar-wrapper">
                    <Avatar
                        size={{xs: 160, sm: 160, md: 160, lg: 200, xl: 200, xxl: 200}}
                        src={<Image src={Store.user.profile?.avatar || avatarImg} alt={"avatar"} />}
                    />
                    <div className="avatar-upload-wrapper">
                        <input
                            className="avatar-upload-input"
                            type="file"
                            id="avatar"
                            name="avatar"
                            accept="image/png, image/jpeg"
                            ref={uploadRef}
                            onChange={onUpload}
                        />
                        <div className="avatar-upload-icon" onClick={onChooseClick}>
                            <Icon name={Store.user.profile?.avatar ? "edit" : "add"} size={"16"} />
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={16}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Typography className="profile-fio gold">
                                {`${Store.user.personal_data?.last_name ?? ""} ${
                                    Store.user.personal_data?.first_name ?? ""
                                } ${Store.user.personal_data?.second_name ?? ""}`}
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[16, 34]}>
                                <Col xs={24} md={11}>
                                    <Typography className="profile-label">{t("Логин")}</Typography>
                                    <div className={"profile-edit-wrapper"}>
                                        <Typography>{Store.user.login}</Typography>
                                        <div className={"profile-edit"}>
                                            <Icon
                                                name={"edit"}
                                                size={"17px"}
                                                onClick={onLoginEdit}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={13}>
                                    <Typography className="profile-label">{t("Пароль")}</Typography>
                                    <div className={"profile-edit-wrapper"}>
                                        <Typography>{"• • • • • • • •"}</Typography>
                                        {Store.user.profile?.email && (
                                            <div className={"profile-edit"}>
                                                <Icon
                                                    name={"edit"}
                                                    size={"17px"}
                                                    onClick={onPasswordEdit}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col xs={24} md={11}>
                                    <Typography className="profile-label">
                                        {t("Тип профиля")}
                                    </Typography>
                                    <div className={"profile-edit-wrapper"}>
                                        <div className={"profile-icon"}>
                                            <Icon
                                                name={
                                                    Store.user.personal_data?.passport_status ===
                                                    "confirmed"
                                                        ? "active"
                                                        : Store.user.personal_data
                                                              ?.passport_status === "moderation"
                                                        ? "status_process"
                                                        : "deny"
                                                }
                                                size={"13px"}
                                            />
                                        </div>
                                        <Typography className="profile-label personal">
                                            {Store.user.personal_data?.passport_status ===
                                            "confirmed"
                                                ? "Подтвержден"
                                                : Store.user.personal_data?.passport_status ===
                                                  "moderation"
                                                ? "На проверке"
                                                : "Не подтвержден"}
                                        </Typography>
                                    </div>
                                </Col>
                                <Col xs={24} md={13}>
                                    <Typography className="profile-label">
                                        {t("Статус лояльности")}
                                    </Typography>
                                    {_.isNil(Store.user.loyalty) && (
                                        <div className={"profile-edit-wrapper"}>
                                            <Typography className="profile-label personal">
                                                Персональный
                                            </Typography>
                                        </div>
                                    )}
                                    {Store.user.loyalty?.code && Store.user.loyalty?.name && (
                                        <div className={"profile-edit-wrapper"}>
                                            <div className={"profile-icon"}>
                                                <Icon
                                                    name={`loyalty_${Store.user.loyalty.code}`}
                                                    size={"16px"}
                                                />
                                            </div>
                                            <Typography className="profile-label">
                                                {Store.user.loyalty.name}
                                            </Typography>
                                        </div>
                                    )}
                                </Col>
                                {Store.user.personal_data?.phone && (
                                    <Col xs={24} md={11}>
                                        <Typography className="profile-label">
                                            {t("Телефон")}
                                        </Typography>
                                        <div className={"profile-edit-wrapper"}>
                                            <Typography>
                                                {Store.user.personal_data?.phone}
                                            </Typography>
                                            <div className={"profile-edit"}>
                                                <Icon
                                                    name={"edit"}
                                                    size={"17px"}
                                                    onClick={
                                                        _.isEqual(
                                                            Store.user.personal_data
                                                                ?.is_phone_bezlimit,
                                                            1
                                                        ) &&
                                                        _.isEqual(
                                                            Store.user.personal_data?.phone_status,
                                                            "confirmed"
                                                        )
                                                            ? onClick
                                                            : onPhoneEdit
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {_.isEqual(
                                            Store.user.personal_data?.is_phone_bezlimit,
                                            1
                                        ) &&
                                        _.isEqual(
                                            Store.user.personal_data?.phone_status,
                                            "confirmed"
                                        ) ? (
                                            <Button
                                                type="primary"
                                                className="btn-notif"
                                                onClick={notif}
                                            >
                                                Подключить скидку
                                            </Button>
                                        ) : null}
                                    </Col>
                                )}
                                {Store.user.profile?.email && (
                                    <Col xs={24} md={13}>
                                        <Typography className="profile-label">
                                            {t("Почта")}
                                        </Typography>
                                        <div className={"profile-edit-wrapper"}>
                                            <Typography>{Store.user.profile?.email}</Typography>
                                            <div className={"profile-edit"}>
                                                <Icon
                                                    name={"edit"}
                                                    size={"17px"}
                                                    onClick={onEmailEdit}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                        <Col span={24}></Col>
                    </Row>
                </Col>
                <Col xs={24} lg={8}></Col>
            </Row>
        </Block>
    );
});
