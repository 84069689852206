import React from "react";
import {Typography, Space, Button} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
//services
import NotificationsService from "../../../services/NewsService";
import BellNotificationsService from "../../../services/BellNotificationsService";

export default React.memo(
    observer(function ReadAllNews({onCancel}: IProps) {
        const {t} = useTranslation();

        const onReadAll = async () => {
            await NotificationsService.postReadAll();
            BellNotificationsService.getNewsNoticeCount();
            onCancel();
        };

        return (
            <Typography as="div" className="bl-read-all-modal">
                <Typography as="div" className="text">
                    {t("Отметить все новости \nкак прочитанные?")}
                </Typography>
                <Space direction="vertical" size={8}>
                    <Button type="primary" onClick={onReadAll}>
                        Подтверждаю
                    </Button>
                    <Button type="default" onClick={onCancel}>
                        Отменить
                    </Button>
                </Space>
            </Typography>
        );
    })
);
interface IProps {
    onCancel: () => void;
}
