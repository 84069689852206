import {Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import React from "react";
import {useTranslation} from "react-i18next";
import bee from "../../../../../../../../images/social_bee.svg";
import vk from "../../../../../../../../images/social_vk.svg";
import ok from "../../../../../../../../images/social_ok.svg";
import wa from "../../../../../../../../images/social_wa.svg";
import tg from "../../../../../../../../images/social_tg.svg";
import vb from "../../../../../../../../images/social_vb.svg";
import sk from "../../../../../../../../images/social_sk.svg";
import yt from "../../../../../../../../images/social_yt.svg";
import ya from "../../../../../../../../images/social_ya.svg";
import tt from "../../../../../../../../images/social_tt.svg";
import nav from "../../../../../../../../images/social_nav.svg";
import gmap from "../../../../../../../../images/social_gmap.svg";
import yamap from "../../../../../../../../images/social_yamap.svg";
import gmail from "../../../../../../../../images/social_gmail.svg";
import mail from "../../../../../../../../images/social_mail.svg";
import bz from "../../../../../../../../images/logo_bez_mobile.svg";
import sp from "../../../../../../../../images/social_sp.svg";
import go from "../../../../../../../../images/social_go.svg";
import kin from "../../../../../../../../images/social_kin.svg";
import _ from "lodash";

export default function Desctop({price}: IProps) {
    const {t} = useTranslation();
    return (
        <Row>
            <Col span={12} className="col-list-service-desctop">
                <Space direction="vertical" size={8}>
                    <Row>
                        <Col span={6}>
                            <img className="social-icon" src={bee} width={16} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div">{t("Безлимит на Билайн")}</Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <img className="social-icon" src={vk} width={16} alt="logo" />
                            <img className="social-icon" src={ok} width={16} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div">{t("Безлимит на Социальные сети")}</Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <img className="social-icon" src={wa} width={16} alt="logo" />
                            <img className="social-icon" src={tg} width={16} alt="logo" />
                            <img className="social-icon" src={vb} width={16} alt="logo" />
                            <img className="social-icon" src={sk} width={16} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div">{t("Безлимит на Мессенджеры")}</Typography>
                        </Col>
                    </Row>
                    {!_.lte(price, 1100) ? (
                        <Row>
                            <Col span={6}>
                                <img className="social-icon" src={yt} width={16} alt="logo" />
                                <img className="social-icon" src={tt} width={16} alt="logo" />
                            </Col>
                            <Col span={18}>
                                <Typography as="div">{t("Безлимит на Видео")}</Typography>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col span={6}>
                                <img className="social-icon" src={kin} width={16} alt="logo" />
                                <img className="social-icon" src={go} width={16} alt="logo" />
                                <img className="social-icon" src={ya} width={16} alt="logo" />
                                <img className="social-icon" src={sp} width={16} alt="logo" />
                            </Col>
                            <Col span={18}>
                                <Typography as="div">{t("Безлимит на сервисы Яндекса")}</Typography>
                            </Col>
                        </Row>
                    )}
                </Space>
            </Col>
            <Col span={12} className="col-list-service-desctop">
                <Space direction="vertical" size={8}>
                    <Row>
                        <Col span={6}>
                            <img className="social-icon" src={yamap} width={16} alt="logo" />
                            <img className="social-icon" src={gmap} width={16} alt="logo" />
                            <img className="social-icon" src={nav} width={14} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div"> {t("Безлимит на Карты")}</Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <img className="social-icon" src={gmail} width={16} alt="logo" />
                            <img className="social-icon" src={mail} width={16} alt="logo" />
                            <img className="social-icon" src={ya} width={16} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div">{t("Безлимит на Почтовые сервисы")}</Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <img className="social-icon" src={bz} width={12} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div">{t("Безлимит на Безлимит")}</Typography>
                        </Col>
                    </Row>
                </Space>
            </Col>
        </Row>
    );
}

interface IProps {
    price: number;
}
