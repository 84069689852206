import {Back, Title} from "@bezlimit/bezlimit-ui";
import {SetStateAction, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import "./style.scss";
import _ from "lodash";
//components
import NotificationsDescription from "../../../blocks/notificationsPages/NotificationsDescription";
import FooterNotificationsDescription from "../../../blocks/notificationsPages/FooterNotificationsDescription";
// store
import Store from "../../../../stores";
import {useNavigate} from "react-router-dom";
import BellNotificationsService from "../../../../services/BellNotificationsService";
import {isMobile} from "react-device-detect";
import Header from "../../../blocks/header";

export default observer(function NotificationsPage() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(20);
    const titles = ["Сегодня", "За неделю", "Ранее"];

    useEffect(() => {
        const f = async () => {
            const res: any = await BellNotificationsService.fetchNotifyNotifications();
            BellNotificationsService.getNewsNoticeCount();
            setPageSize(res._meta.totalCount);
        };
        f();
        return () => {
            BellNotificationsService.getNewsNoticeCount();
        };
    }, [_.get(Store.user, "mainNumber.phone")]);

    const [IconSwitchIndex, setIconSwitchIndex] = useState("СubeFilter");
    const setIconSwitchIndexCallback = (x: SetStateAction<string>) => {
        setIconSwitchIndex(x);
    };

    const clickPageSizeCallback = () => {
        BellNotificationsService.fetchNotifyNotifications(
            BellNotificationsService.meta.perPage,
            BellNotificationsService.meta.currentPage + 1
        );
    };

    const [windowInnerWidt, setWindowInnerWidt] = useState(document.documentElement.clientWidth);

    window.onresize = function () {
        setWindowInnerWidt(document.documentElement.clientWidth);
        if (windowInnerWidt < 1045) {
            setIconSwitchIndex("СubeFilter");
        }
    };

    // useEffect(() => {
    //     if (BellNotificationsService.items) {
    //         const filteredItems = BellNotificationsService.items
    //             .filter((i, k) => _.lte(k, pageSize))
    //             .map((item, index) => {
    //                 if (!item?.is_read) {
    //                     return item.id;
    //                 }
    //             })
    //             .filter((id) => id);
    //         _.gte(filteredItems.length, 1) &&
    //             BellNotificationsService.postNewActions(filteredItems);
    //     }
    // }, [pageSize, BellNotificationsService.items]);

    const toggle = () => {
        (document.body.querySelector(".paralax-btn") as any)?.click();
    };

    return (
        <>
            {isMobile && <Header toggle={toggle} />}
            <Back navigate={() => navigate("/")}>{t("Назад в личный кабинет")}</Back>

            <div className="notifications-page-wrapper-titlePageHeader">
                <Title title="Уведомления" />
            </div>
            <div className="notifications-page-wrapper">
                {titles.map((title, index) => (
                    <NotificationsDescription
                        NotifyNotifications={BellNotificationsService.items ?? []}
                        title={title}
                        switchIndex={index}
                        key={title.toString()}
                        IconSwitchIndex={IconSwitchIndex}
                        setIconSwitchIndexCallback={setIconSwitchIndexCallback}
                        pageSize={pageSize}
                    />
                ))}
                <FooterNotificationsDescription
                    NotifyNotifications={BellNotificationsService.items ?? []}
                    pageSize={pageSize}
                    clickPageSizeCallback={clickPageSizeCallback}
                />
            </div>
        </>
    );
});
