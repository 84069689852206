import React, {useContext} from "react";
import {Button, Col, Row, Space, Title, Typography, Back} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
//service
import NotificationsService from "../../../../services/NewsService";
//components
import BlContent from "./blContent";
//core
import {InterceptorContext, ModalContext} from "../../../../core/Contexts";
// hooks
import useSearchRoute from "../../../../core/hooks/useSearchRoute";
// services
import UserService from "../../../../services/UserService";
import BellNotificationsService from "../../../../services/BellNotificationsService";
import ReadAllNews from "../../../modal/readAllNews";
import {isMobile} from "react-device-detect";
import Header from "../../../blocks/header";

export default observer(function NewsPage() {
    const context = useContext(InterceptorContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const route = useSearchRoute(UserService.token);
    const contextModal = useContext(ModalContext);

    const onClick = async () => {
        await NotificationsService.loadMoreNews({page: NotificationsService.currentPage + 1});
    };

    const onCancel = () => {
        contextModal?.setVisible(false);
        contextModal?.setCssClass("");
    };

    const onReadAll = () => {
        contextModal?.setMaskStyle({
            background: "none"
        });
        contextModal?.setVisible(true);
        contextModal?.setCssClass(" bl-news-readall-modal");
        contextModal?.setChildren(<ReadAllNews onCancel={onCancel} />);
        contextModal?.setOnCancel(() => onCancel);
    };

    useEffect(() => {
        NotificationsService.fetchNews();
        BellNotificationsService.getNewsNoticeCount();
    }, []);

    const toggle = () => {
        (document.body.querySelector(".paralax-btn") as any)?.click();
    };

    return (
        <>
            {isMobile && <Header toggle={toggle} />}
            <Typography as="div" className="bl-news">
                <Row>
                    <Back navigate={() => navigate("/")} />
                    <Col span={24}>
                        <Title title={_.get(route, "title")} />
                    </Col>
                    {!_.isEqual(BellNotificationsService.newsNoticeCount?.news_count ?? 0, 0) ? (
                        <Col span={24} className="col-btn-news-count">
                            <Button
                                onClick={onReadAll}
                                icon={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="33"
                                        height="32"
                                        viewBox="0 0 33 32"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 31V11.2727M1 31L15.4814 16.4422C15.8666 16.055 16.4913 16.0484 16.8845 16.4275L32 31M1 31H32M1 11.2727L10.7698 21.1787M1 11.2727L7.34091 6.56262M22.0126 21.3714C25.9129 17.4276 32 11.2727 32 11.2727M32 11.2727C32 11.2727 32 26.5977 32 31M32 11.2727L25.3881 6.56262M11.5682 3.74428L14.9941 0.960232C15.6922 0.392922 16.6832 0.361489 17.4158 0.883413L21.4318 3.74428"
                                            stroke="white"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M7 17V6.30248V4H25V7.11512V18.272M11.5 8.60497H16.0643H20.6286M11.5 13.3454H20.6286"
                                            stroke="white"
                                            stroke-linecap="round"
                                        />
                                    </svg>
                                }
                            ></Button>
                        </Col>
                    ) : null}

                    <Space direction="vertical" className="list-news-block" size={16}>
                        {_.chain(NotificationsService.items)
                            .map(
                                (
                                    {
                                        id,
                                        preview_text,
                                        published_date,
                                        title,
                                        preview_image,
                                        is_read
                                    },
                                    key
                                ) => (
                                    <React.Fragment key={key}>
                                        <Col span={24}>
                                            <BlContent
                                                published_date={published_date}
                                                title={title}
                                                preview_text={preview_text}
                                                id={id}
                                                preview_image={preview_image}
                                                is_read={is_read}
                                            />
                                        </Col>
                                    </React.Fragment>
                                )
                            )
                            .valueOf()}
                    </Space>
                    <Col span={24}>
                        <Typography as="div" className="btn-show">
                            <Button
                                className="btn-show-more"
                                onClick={onClick}
                                disabled={!NotificationsService.hasItems}
                                loading={context?.loadingBtn}
                            >
                                {t("Показать еще")}
                            </Button>
                        </Typography>
                    </Col>
                </Row>
            </Typography>
        </>
    );
});
